import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const GalleryMasonry = () => {
   return (
      <>
         <div class='lg:px-32 px-4 py-8 mx-auto items-center relative z-40'>
            <div class='grid grid-cols-4 grid-flow-row gap-2'>
               {/* Full Grid */}
               <div class='w-full row-span-2 col-span-2 md:col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-113.jpg'
                     alt='Just a Nibble Catering Food and Event'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                  />
               </div>
               <div class='w-full col-span-2 row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-116.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-114.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-115.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-117.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-118.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 md:col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-119.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 row-span-2 md:row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-120.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               {/* Split */}
               <div className='w-full col-span-3 row-span-2 md:row-span-2 md:col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-122.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div className='w-full col-span-4 md:col-span-2 row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-123.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div className='w-full col-span-4 row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-125.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               {/* Full Grid */}
               <div class='w-full row-span-2 col-span-2 md:col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-126.jpg'
                     alt='Just a Nibble Catering Food and Event'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                  />
               </div>
               <div class='w-full col-span-2 row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-127.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 row-span-2 md:row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-128.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 row-span-2 md:row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-129.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-130.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-2 md:row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-133.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-132.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-131.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               {/* Full Grid-2 */}
               <div class='w-full row-span-1 col-span-2 md:col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-134.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full row-span-1 col-span-2 md:col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-135.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-136.jpg'
                     alt='Just a Nibble Catering Food and Event'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                  />
               </div>
               <div class='w-full col-span-2 row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-143.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>

               <div class='w-full col-span-2 row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-137.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-3 md:row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-139.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-3 md:row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-140.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-141.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-142.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               {/* Full Grid */}
               <div class='w-full row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-138.jpg'
                     alt='Just a Nibble Catering Food and Event'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                  />
               </div>
               <div class='w-full col-span-3 md:col-span-2 row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-144.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-145.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-146.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-147.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-2 md:row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-148.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 col-span-4'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-149.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full md:col-span-1 col-span-3'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-150.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-1 md:col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-151.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 md:col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-152.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 md:col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-154.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-3'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-156.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-155.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-4 md:col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-153.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 md:col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-100.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 md:col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-104.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-101.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>

               <div class='w-full col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-103.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-4 md:col-span-3'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-157.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 md:col-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-105.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 hidden md:block'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-106.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-108.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-107.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-109.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 row-span-4 md:row-span-2'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-112.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
               <div class='w-full col-span-2 md:col-span-4 row-span-2 md:row-span-1'>
                  <StaticImage
                     src='../images/GalleryMasonry/Gallery-Just-A-Nibble-110.jpg'
                     class='inset-0 h-full w-full object-cover object-center rounded'
                     alt='Just a Nibble Catering Food and Event'
                  />
               </div>
            </div>
         </div>
      </>
   );
};

export default GalleryMasonry;
