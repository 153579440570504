import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import LogoLinks from '../components/LogoLinks';
import GalleryMasonry from '../components/GalleryMasonry';

import background from '../images/slate.jpg';

const GalleryPage = () => {
   return (
      <Layout>
         <Seo title='Services' />
         <header className='w-full'>
            <div className='relative'>
               <div className='absolute inset-x-0' />
               <div className='relative h-full'>
                  <div className='absolute inset-0'>
                     <StaticImage
                        src='../images/Gallery-Just-A-Nibble-Hero.jpg'
                        quality='100'
                        loading='eager'
                        className='h-full w-full object-cover'
                        alt='A caterer passing hors d oeuvres'
                     />
                     <div aria-hidden='true' className='opacity-60 md:opacity-70 absolute inset-0 bg-gradient-to-b from-black to-transparent' />
                  </div>
                  <div className='relative mx-auto max-w-7xl px-4 pt-96 text-center sm:px-6 lg:px-8 pb-36'>
                     <h1 className='mt-12 text-5xl font-hanley-script relative sm:text-6xl lg:text-8xl'>
                        <span className='block header'>Gallery</span>
                     </h1>
                     <motion.hr
                        initial={{ width: 0, opacity: 0 }}
                        whileInView={{ width: 200, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className='border-brand border rounded w-1/2 mx-auto my-3'
                     />
                     <motion.h2
                        initial={{ y: 10, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className='text-white text-base md:text-xl uppercase tagline'
                     >
                        See Us in Action
                     </motion.h2>
                  </div>
               </div>
            </div>
         </header>
         <Gallery />
         <LogoLinks />
      </Layout>
   );
};

const Gallery = () => {
   return (
      <section className='relative w-full py-14 bg-repeat-y bg-cover bg-fixed' style={{ backgroundImage: `url(${background})` }}>
         <div aria-hidden='true' className='opacity-60 md:opacity-80 absolute inset-0 bg-black' />
         <div className='relative flex z-50 items-center justify-center py-2 w-full mx-auto'>
            <StaticImage
               src='../images/Logo-Just-A-Nibble-Utensils-White.png'
               className='opacity-50 '
               placeholder='tracedSVG'
               alt='Just a Nibble Utensils Icon'
               quality={100}
               height={125}
            />
            <motion.p
               initial={{ opacity: 0 }}
               whileInView={{ opacity: 1 }}
               transition={{ duration: 1, delay: 1 }}
               viewport={{ once: true }}
               className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-brand font-hanley-sans text-center tracking-widest uppercase font-boldest z-20'
            >
               Get a Glimpse
            </motion.p>
         </div>
         <div className='max-w-6xl py-8 px-8 md:px-4 mx-auto items-center '>
            <GalleryMasonry />
         </div>
      </section>
   );
};

export default GalleryPage;
